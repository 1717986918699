import React from "react";
import './disclaimers.css'

const Policy = () =>{
    return(
        <div className="policy">
            <h1>Domits Privacy Policy</h1>
            <input type="text" placeholder="Search within privacy policy..." />

            <h3>Privacy Policy</h3>
            <p>Last updated: November, 2024</p>
            <p>We are aware that you place your trust in us. We therefore see it as our responsibility to protect your privacy.
                 On this page we let you know what data we collect when you use our website, why we collect this data and how we improve your user experience. 
                 This way you understand exactly how we work.
            </p>
            <p>This privacy policy applies to the services of Domits. 
                You should be aware that Domits is not responsible for the privacy policy of other sites and sources. 
                By using this website you agree to the privacy policy.
            </p>
            <p>
            Domits respects the privacy of all users of its site and ensures that the personal information you provide is treated confidentially. 
            </p>

            <h3>Pur Use of Collected Data</h3>
            <h4>Use our services</h4>
            <p>When you register for one of our services, we ask you to provide personal data. 
                The purpose of the processing is to perform the service. The personal data that we initially process are: Name, telephone number and email address. 
                The data is stored on Domits's own secure servers or those of a third party. We will not combine this data with other personal data we have.</p>
            <br />
            <h3>Communication</h3>
            <br />
            <p>When you send us an email or other messages, we may store those messages. Sometimes we ask you for your personal information that is relevant to the situation. 
                This makes it possible to process your questions and answer your requests. The data is stored on Domits's own secure servers or those of a third party.
                 We will not combine this data with other personal data we have.</p>

            <h3>Cookies</h3>
            <p>We collect data for research to gain a better understanding of our customers so that we can tailor our services accordingly.</p>

            <p>This website uses “cookies” (text files placed on your computer) to help the website analyze how users use the site. 
                The information generated by the cookie about your use of the website can be transferred to Domits's own secure servers or those of a third party. 
                We use this information to keep track of how you use the website, 
                to compile reports on website activity and to offer other services relating to website activity and internet usage.
            </p>
            <h3>Purposes</h3>
            <p>We do not collect or use information for purposes other than those described in this privacy policy unless we have obtained your permission in advance. </p>

            <h3>Third parties</h3>
            <p>The people at Domits do their best to respect everyone's privacy, process as little personal data as possible and protect all personal data as best as possible. 
                The information is not shared with third parties. In some cases the information can be shared internally. 
                Our employees are obliged to respect the confidentiality of your data.</p>

            <h3>Changes</h3>
            <p>This privacy statement is tailored to the use of and the possibilities on this site. Any adjustments and/or changes to this site may lead to changes in this privacy statement. 
                It is therefore advisable to regularly consult this privacy statement.</p>

            <h3>Choices for personal data</h3>
            <p>We offer all visitors the opportunity to view, change or delete all personal information that has been provided to us at the time.</p>    
             
            <h3>Adjust/unsubscribe from the newsletter service</h3>
            <p>At the bottom of every mailing you will find the option to change your details or unsubscribe.</p>

            <h3>Adjust/unsubscribe communication</h3>
            <p>If you would like to change your data or have it removed from our files yourself, you can contact us. See contact details below.</p>

            <h3>Disable cookies</h3>
            <p>Most browsers are set to accept cookies by default, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, 
                some features and services - on our and other websites - may not function correctly if cookies are disabled in your browser.</p>

            <h3>Questions and feedback</h3>
            <p>We regularly check whether we comply with this privacy policy. If you have any questions about this privacy policy, please contact us.</p>

        </div>
    );
}

export default Policy;